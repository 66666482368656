import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Hidden, Grid, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik } from 'formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { AddAmcAdminRequestBody, AmcAdmin } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addAmcAdmin,
  AmcAdminAccess,
  getAmcAdminAccess,
  getAMCAdminById,
  updateAmcAdmin,
} from '../../redux-store/actions/userManagement';
import { amcAdminSchema } from '../../utils/schema';
import { Location } from 'history';
import { CountryCodesDropDown } from '../commonComponents';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: AddAmcAdminRequestBody = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  isActive: true,
};

export default function AddAmcAdmin({
  location,
}: {
  location: Location<{ amcAdminId: number }>;
}): JSX.Element {
  const { amcAdminId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  const [amcAdminDetails, setAmcAdminDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { amcAdminAccess } = (await dispatch(
          getAmcAdminAccess()
        )) as unknown as AmcAdminAccess;
        if (amcAdminId) {
          const getAmcAdminDetails = (await dispatch(
            getAMCAdminById(amcAdminId)
          )) as unknown as AmcAdmin;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getAmcAdminDetails.user;
          setAmcAdminDetails({
            ...amcAdminDetails,
            id: getAmcAdminDetails.id,
            name: name as string,
            phone,
            email,
            countryNameAndCode: (countryNameAndCode ? countryNameAndCode : 'India: +91') as string,
            countryCode: (countryCode ? countryCode : '+91') as string,
            isActive: getAmcAdminDetails.isActive,
          });
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(amcAdminAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddAmcAdminRequestBody) => {
    try {
      if (!amcAdminId) {
        await dispatch(
          addAmcAdmin({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('amc-admin');
      } else {
        await dispatch(
          updateAmcAdmin(amcAdminId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('amc-admin-details', { amcAdminId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={amcAdminDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={amcAdminSchema}>
        {({ handleSubmit, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    amcAdminId
                      ? history.push('amc-admin-details', { amcAdminId: values.id })
                      : history.push('amc-admin')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    amcAdminId
                      ? history.push('amc-admin-details', { amcAdminId: values.id })
                      : history.push('amc-admin')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {amcAdminId ? 'AMC Admin' : 'Onboarding AMC Admin'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name *"
                      placeholder="Enter Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
