import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { AdditionalReturn, InfoDialog, ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  ApplicationProps,
  DistributorListType,
  JointholderRelationMaster,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  PlansCategoryMasters,
  PlansFeeTypeMaster,
  setupFeeRemovalDate,
  SIP_START_DATE_MASTER,
  sipStartDateValidationDate,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkValidationBasedOnDate,
  currencyConversion,
  saveForLater,
  setUpFeeCalculations,
  sipTypeInvestmentCategory,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import { Item } from '../ApplicationDetails';
import ExitLoad from '../ApplicationDetails/exitLoad';
import DefaulterPenalty from '../ApplicationDetails/defaulterPenalty';

type Values = Partial<ApplicationProps> & {
  saveType: string;
  planName: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  feeType: '',
  category: '',
  additionalReturnWithoutCatchup: '',
  catchup: '',
  hurdleRateOfReturn: '',
  defaulterPenalty: 'NA',
  sipStartDate: '',
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  saveType: 'save and proceed',
  planName: '',
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const [open, setOpen] = useState(false);
  const [plansMaster, setPlansMaster] = useState<ClassPlanProps[]>([]);

  const {
    // minCommitmentAmount = 10000000,
    // maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    setupFee = 0,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      //minCommitmentAmount = 1,
      //maxCommitmentAmount = 0,
      exitLoad = '',
      minContributionPercentage = 1,
      setupFee = 0,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            // minCommitmentAmount: Number(minCommitmentAmount),
            // maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            setupFee: Number(setupFee),
            exitLoad,
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || null,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      feeType: application?.feeType || '',
      category: application?.category || '',
      additionalReturnWithoutCatchup: application?.additionalReturnWithoutCatchup || '',
      catchup: application?.catchup || '',
      hurdleRateOfReturn: application?.hurdleRateOfReturn || '',
      planName: application?.plan?.planDescription || '',
      defaulterPenalty: application?.scheme?.defaulterPenalty,
      sipStartDate: application?.sipStartDate || '',
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { saveType, planName, defaulterPenalty, sipStartDate, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...application,
          ...rest,
          contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          sipStartDate:
            sipTypeInvestmentCategory(rest.category || '') &&
            checkValidationBasedOnDate(application?.createdAt || '', sipStartDateValidationDate)
              ? sipStartDate
              : null,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 7
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
              setupFeePercentage: Number(rest.setupFeePercentage),
              sipStartDate:
                sipTypeInvestmentCategory(rest.category || '') &&
                checkValidationBasedOnDate(application?.createdAt || '', sipStartDateValidationDate)
                  ? sipStartDate
                  : null,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 8,
              // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              //   ? !!currentStep && currentStep > 3
              //     ? currentStep
              //     : Number(currentStep) + 1
              //   : !!currentStep && currentStep > 7
              //   ? currentStep
              //   : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan = application?.schemeId
          ? !application.scheme.isActive
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : (response.map((res) => res.id?.toString()) as unknown as string[]).includes(
                application?.schemeId?.toString()
              )
            ? response
            : [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
          : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        getClassPlanOptionsOrDetails(
          updatePlans?.plans || [],
          application?.category || '',
          application?.feeType || '',
          application?.commitmentAmount || null,
          false
        );
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);
  const getCommitmentAmountLimits = (
    plans: ClassPlanProps[],
    category: string | undefined,
    feeType: string | undefined
  ) => {
    const amountObj = {
      minCommitmentAmount: 10000000,
      maxCommitmentAmount: 0,
    };
    amountObj.minCommitmentAmount =
      plans.length > 0 && category && feeType
        ? plans
            .filter((plan) => plan.category === category)
            .filter((plan) => plan.feeType === feeType)
            .map((_plan) => Number(_plan.minCommitmentAmount))
            .sort((a, b) => a - b)[0]
        : amountObj.minCommitmentAmount;
    amountObj.maxCommitmentAmount =
      plans.length > 0 && category && feeType
        ? plans
            .filter((plan) => plan.category === category)
            .filter((plan) => plan.feeType === feeType)
            .map((_plan) => Number(_plan.maxCommitmentAmount))
            .sort((a, b) => b - a)[0]
        : amountObj.maxCommitmentAmount;

    return amountObj;
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getClassPlanOptionsOrDetails = (
    plans: ClassPlanProps[],
    category: string | undefined,
    feeType: string | undefined,
    commitmentAmount: number | null | undefined,
    showPopUp = true
  ) => {
    const finalPlan = plans
      .map((_plan) => {
        if (
          commitmentAmount &&
          _plan.category === category &&
          feeType === _plan.feeType &&
          commitmentAmount >= _plan.minCommitmentAmount &&
          commitmentAmount <= _plan.maxCommitmentAmount
        ) {
          return _plan;
        }
        return;
      })
      .filter((ele) => ele);
    if (finalPlan.length > 1 && showPopUp) {
      handleOpen();
    }
    setPlansMaster(finalPlan as ClassPlanProps[]);
    return finalPlan;
  };

  const getPlansCategoryMasters = (): string[] => {
    const getSchemeNature = selectedFund?.plans.map((_plan) => _plan.schemeNature) || [];
    const getPlanCategory = selectedFund?.plans.map((_plan) => _plan.category) || [];

    if (getSchemeNature.includes('Close') && !getSchemeNature.includes('Open')) {
      if (
        getPlanCategory.includes(PlansCategoryMasters.SIP) &&
        !getPlanCategory.includes(PlansCategoryMasters.Drawdown)
      ) {
        return Object.keys(PlansCategoryMasters).filter(
          (_category: string | number) =>
            PlansCategoryMasters[_category] === PlansCategoryMasters.SIP // ['SIP']
        );
      }
      if (
        getPlanCategory.includes(PlansCategoryMasters.Drawdown) &&
        !getPlanCategory.includes(PlansCategoryMasters.SIP)
      ) {
        return Object.keys(PlansCategoryMasters).filter(
          (category: string | number) =>
            PlansCategoryMasters[category] === PlansCategoryMasters.Drawdown // ['Drawdown']
        );
      }
      return Object.keys(PlansCategoryMasters).filter(
        (cat: string | number) => PlansCategoryMasters[cat] !== PlansCategoryMasters.Full
      );
    }
    if (getSchemeNature.includes('Open') && !getSchemeNature.includes('Close')) {
      return Object.keys(PlansCategoryMasters).filter(
        (_cat: string | number) => PlansCategoryMasters[_cat] === PlansCategoryMasters.Full
      );
    }

    if (
      getPlanCategory.includes(PlansCategoryMasters.SIP) &&
      !getPlanCategory.includes(PlansCategoryMasters.Drawdown)
    ) {
      return Object.keys(PlansCategoryMasters).filter(
        (_category: string | number) =>
          PlansCategoryMasters[_category] !== PlansCategoryMasters.Drawdown
      );
    }
    if (
      getPlanCategory.includes(PlansCategoryMasters.Drawdown) &&
      !getPlanCategory.includes(PlansCategoryMasters.SIP)
    ) {
      return Object.keys(PlansCategoryMasters).filter(
        (category: string | number) => PlansCategoryMasters[category] !== PlansCategoryMasters.SIP
      );
    }

    return Object.keys(PlansCategoryMasters);
  };

  const getPlansFeeTypeMasters = (category: string | undefined): string[] => {
    const getPlanFeeType =
      selectedFund?.plans
        .filter((plan) => plan.category === category)
        .map((_plan) => _plan.feeType) || [];

    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
      getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (_fee: string | number) => PlansFeeTypeMaster[_fee] !== PlansFeeTypeMaster.Hybrid
      );
    }
    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (fee: string | number) => PlansFeeTypeMaster[fee] !== PlansFeeTypeMaster.Fixed
      );
    }
    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
      getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (fee: string | number) => PlansFeeTypeMaster[fee] !== PlansFeeTypeMaster.Variable
      );
    }
    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (fee: string | number) => PlansFeeTypeMaster[fee] === PlansFeeTypeMaster.Hybrid
      );
    }
    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (fee: string | number) => PlansFeeTypeMaster[fee] === PlansFeeTypeMaster.Fixed
      );
    }
    if (
      getPlanFeeType.includes(PlansFeeTypeMaster.Variable) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
      !getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid)
    ) {
      return Object.keys(PlansFeeTypeMaster).filter(
        (fee: string | number) => PlansFeeTypeMaster[fee] === PlansFeeTypeMaster.Variable
      );
    }

    return Object.keys(PlansFeeTypeMaster);
  };
  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const commitmentAmountLimits = getCommitmentAmountLimits(
          selectedFund?.plans || [],
          values.category,
          values.feeType
        );
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1 && jointHoldersData
            ? (updatedCommitmentAmount(
                Number(commitmentAmountLimits.minCommitmentAmount),
                application.applicants,
                jointHoldersData
              ) as number)
            : commitmentAmountLimits.minCommitmentAmount;
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              minCommitmentAmountBasedOnJointHolders,
              commitmentAmountLimits.maxCommitmentAmount,
              minContributionPercentage,
              setupFee,
              fundsList,
              getPlansCategoryMasters().map((planCategory) => PlansCategoryMasters[planCategory]),
              getPlansFeeTypeMasters(values.category).map((FeeType) => PlansFeeTypeMaster[FeeType]),
              application?.createdAt || ''
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const getSchemeNature =
                  fundsList
                    .find((f) => Number(f.id) === value)
                    ?.plans.map((_plan) => _plan.schemeNature) || [];
                const getPlanFeeType =
                  fundsList
                    .find((f) => Number(f.id) === value)
                    ?.plans.map((_plan) => _plan.feeType) || [];
                const getPlanCategory =
                  fundsList
                    .find((f) => Number(f.id) === value)
                    ?.plans.map((_plan) => _plan.category) || [];
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  defaulterPenalty: fundsList.find((f) => Number(f.id) === value)?.defaulterPenalty,
                  category:
                    getSchemeNature.includes('Open') && !getSchemeNature.includes('Close')
                      ? PlansCategoryMasters.Full
                      : getPlanCategory.includes(PlansCategoryMasters.SIP) &&
                        !getPlanCategory.includes(PlansCategoryMasters.Drawdown) &&
                        !getPlanCategory.includes(PlansCategoryMasters.Full)
                      ? PlansCategoryMasters.SIP
                      : getPlanCategory.includes(PlansCategoryMasters.Drawdown) &&
                        !getPlanCategory.includes(PlansCategoryMasters.SIP) &&
                        !getPlanCategory.includes(PlansCategoryMasters.Full)
                      ? PlansCategoryMasters.Drawdown
                      : '',
                  feeType:
                    getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
                    !getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
                    !getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
                      ? PlansFeeTypeMaster.Fixed
                      : getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid) &&
                        !getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
                        !getPlanFeeType.includes(PlansFeeTypeMaster.Variable)
                      ? PlansFeeTypeMaster.Hybrid
                      : getPlanFeeType.includes(PlansFeeTypeMaster.Variable) &&
                        !getPlanFeeType.includes(PlansFeeTypeMaster.Fixed) &&
                        !getPlanFeeType.includes(PlansFeeTypeMaster.Hybrid)
                      ? PlansFeeTypeMaster.Variable
                      : '',
                  planName: '',
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setPlansMaster([]);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
              applyFullHeight={true}
            />
          </Grid>
          {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
            <UseRadioGroup
              formLabel="Category *"
              name={`category`}
              items={getPlansCategoryMasters().map((planCategory) => ({
                label: planCategory,
                value: PlansCategoryMasters[planCategory],
              }))}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  commitmentAmount: 0,
                  setupFeePercentage: 0,
                  managementFeesRate: 0,
                  contributionChequeAmount: 0,
                  feeType:
                    getPlansFeeTypeMasters(value).length === 1
                      ? PlansFeeTypeMaster[
                          getPlansFeeTypeMasters(value)[getPlansFeeTypeMasters(value).length - 1]
                        ]
                      : '',
                  planName: '',
                  category: value,
                });
                setPlansMaster([]);
              }}
              disabled={isFieldDisabled || !values.schemeId}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
            <UseRadioGroup
              formLabel="Fee type *"
              name={`feeType`}
              items={getPlansFeeTypeMasters(values.category).map((FeeType) => ({
                label: FeeType,
                value: PlansFeeTypeMaster[FeeType],
              }))}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const getPlans = getClassPlanOptionsOrDetails(
                  selectedFund?.plans || [],
                  values.category,
                  value,
                  values.commitmentAmount
                ) as ClassPlanProps[];
                const getPlan = getPlans
                  ? getPlans.length > 1
                    ? null
                    : getPlans[getPlans.length - 1]
                  : null;
                setSelectedPlan(getPlan || null);
                const feeAmountObj = setUpFeeCalculations(Number(values.commitmentAmount), 0);
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                const contributionAmount =
                  values.commitmentAmount && getPlan
                    ? (Number(values.commitmentAmount) *
                        Number(getPlan?.minContributionPercentage)) /
                      100
                    : 0;
                setValues({
                  ...values,
                  setupFeePercentage: 0,
                  managementFeesRate: getPlan?.managementFee || 0,
                  planId: getPlan ? Number(getPlan.id) : '',
                  planName: getPlan?.planDescription || '',
                  feeType: value,
                  contributionChequeAmount: contributionAmount,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: contributionAmount || values.commitmentAmount,
                  additionalReturnWithoutCatchup: getPlan?.additionalReturnWithoutCatchup || '',
                  catchup: getPlan?.catchup || '',
                  hurdleRateOfReturn: getPlan?.hurdleRateOfReturn || '',
                });
              }}
              disabled={isFieldDisabled || !values.schemeId}
            />
          </Grid>
          {sipTypeInvestmentCategory(values.category || '') &&
            checkValidationBasedOnDate(
              application?.createdAt || '',
              sipStartDateValidationDate
            ) && (
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  formLabel="SIP Start Date *"
                  name={`sipStartDate`}
                  items={Object.keys(SIP_START_DATE_MASTER).map((item) => ({
                    label: item,
                    value: SIP_START_DATE_MASTER[item],
                  }))}
                  disabled={isFieldDisabled || !values.schemeId}
                />
              </Grid>
            )}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'commitmentAmount'}
              label="Commitment Amount *"
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const getPlans = getClassPlanOptionsOrDetails(
                  selectedFund?.plans || [],
                  values.category,
                  values.feeType,
                  Number(value)
                ) as ClassPlanProps[];
                const getPlan = getPlans
                  ? getPlans.length > 1
                    ? null
                    : getPlans[getPlans.length - 1]
                  : null;
                setSelectedPlan(getPlan || null);
                const feeAmountObj = setUpFeeCalculations(Number(value), 0);
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  setupFeePercentage: 0,
                  managementFeesRate: getPlan?.managementFee || 0,
                  planId: getPlan ? Number(getPlan.id) : '',
                  planName: getPlan?.planDescription || '',
                  commitmentAmount: value ? Number(value) : null,
                  contributionChequeAmount: getPlan
                    ? (Number(value) * Number(getPlan?.minContributionPercentage)) / 100
                    : 0,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(
                    (Number(value) * Number(getPlan?.minContributionPercentage)) / 100
                  )
                    ? Number((Number(value) * Number(getPlan?.minContributionPercentage)) / 100)
                    : Number(value),
                  additionalReturnWithoutCatchup: getPlan?.additionalReturnWithoutCatchup || '',
                  catchup: getPlan?.catchup || '',
                  hurdleRateOfReturn: getPlan?.hurdleRateOfReturn || '',
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.feeType || !values.category}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {plansMaster?.length > 1 ? (
              <SearchableSelect
                name={'planId'}
                label="Class (Plan) *"
                items={plansMaster?.map((plan) => ({
                  key: plan?.planDescription || '',
                  value: Number(plan?.id) || '',
                }))}
                disabled={!values.schemeId || isFieldDisabled}
                onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                  const selectedPlan =
                    application?.planId && Number(value) === Number(application?.planId)
                      ? (application.plan as unknown as ClassPlanProps)
                      : selectedFund?.plans
                          ?.filter((plan) => plan.isActive)
                          ?.find((p) => Number(p.id) === value);
                  setSelectedPlan(selectedPlan || null);
                  const feeAmountObj = setUpFeeCalculations(Number(values.commitmentAmount), 0);
                  const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);

                  const contributionAmount =
                    values.commitmentAmount && selectedPlan
                      ? (Number(values.commitmentAmount) *
                          Number(selectedPlan?.minContributionPercentage)) /
                        100
                      : 0;
                  setValues({
                    ...values,
                    planId: value as string,
                    planName: selectedPlan?.planDescription as string,
                    setupFeePercentage: 0,
                    managementFeesRate: selectedPlan?.managementFee,
                    contributionChequeAmount: contributionAmount,
                    setupFeeAmount: feeAmountObj.feeAmount,
                    setupFeeGST: feeAmountObj.feeGst,
                    totalSetupFee: feeAmountObj.totalSetupFee,
                    netSetupFeeLessTDS: netSetupFee,
                    totalAmountPayable: contributionAmount || values.commitmentAmount,
                    additionalReturnWithoutCatchup:
                      selectedPlan?.additionalReturnWithoutCatchup || '',
                    catchup: selectedPlan?.catchup || '',
                    hurdleRateOfReturn: selectedPlan?.hurdleRateOfReturn || '',
                  });
                }}
                searchFieldPlaceholder={'Search Class (Plan)'}
              />
            ) : (
              <MFTextField
                name={'planName'}
                label="Class (Plan) *"
                placeholder="Enter Class (Plan)"
                disabled={true}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'managementFeesRate'}
              label="Management Fees Rate"
              placeholder="Enter Management Fee Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Initial Contribution Amount *"
              placeholder="Enter Initial Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(values.commitmentAmount),
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) ? Number(value) : values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount)}
              </Typography>
            )}
          </Grid>
          {!checkValidationBasedOnDate(application?.createdAt || '', setupFeeRemovalDate) && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'setupFeePercentage'}
                  label="Setup Fee Percentage *"
                  type="number"
                  placeholder="Enter Setup Fee Percentage"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const feeAmountObj = setUpFeeCalculations(
                      Number(values.commitmentAmount) || 0,
                      Number(value)
                    );
                    const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                    setValues({
                      ...values,
                      setupFeePercentage: value ? Number(value) : null,
                      setupFeeAmount: feeAmountObj.feeAmount,
                      setupFeeGST: feeAmountObj.feeGst,
                      totalSetupFee: feeAmountObj.totalSetupFee,
                      netSetupFeeLessTDS: netSetupFee,
                      totalAmountPayable: values.contributionChequeAmount
                        ? values.contributionChequeAmount
                        : values.commitmentAmount,
                    });
                  }}
                  trimOnBlur={false}
                  // disabled={isFieldDisabled || !values.planId}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeAmount'}
                  label="Setup Fee Amount"
                  type="number"
                  placeholder="Enter Setup Fee Amount"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeAmount != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeAmount)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeGST'}
                  label="GST (GST On Setup Fee Amount)"
                  type="number"
                  placeholder="Enter GST"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeGST != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeGST)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'totalSetupFee'}
                  label="Total Setup Fee"
                  type="number"
                  placeholder="Enter Total Setup Fee"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.totalSetupFee != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.totalSetupFee)}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeTDS'}
              label="TDS"
              type="number"
              placeholder="Enter TDS"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                setValues({
                  ...values,
                  setupFeeTDS: value ? Number(value) : null,
                  netSetupFeeLessTDS:
                    values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                  totalAmountPayable:
                    values.totalSetupFee && value
                      ? (values.contributionChequeAmount || 0) + setupFeeLessTDS
                      : (values.contributionChequeAmount || 0) + (values.totalSetupFee || 0),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.setupFeeTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeTDS)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'netSetupFeeLessTDS'}
              label="Setup Fee Payable"
              type="number"
              placeholder="Enter Setup Fee Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.netSetupFeeLessTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.netSetupFeeLessTDS)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable)}
              </Typography>
            )}
          </Grid> */}
          {selectedPlan && (
            <Grid item xs={12} mt={2}>
              <AdditionalReturn
                additionalReturn={values.additionalReturnWithoutCatchup as string}
                catchup={values.catchup as string}
                hurdleRate={values.hurdleRateOfReturn as string}
                planId={values?.planId}
              />
            </Grid>
          )}

          <Grid item xs={12} pb={2}>
            <DefaulterPenalty defaulterPenalty={values?.defaulterPenalty} />
          </Grid>
          <Grid item xs={12} pb={2}>
            <ExitLoad exitLoad={selectedPlan?.exitLoad} />
          </Grid>
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid>
          {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={
              'Multiple plans found for the combination of feeType,category and commitment amount, please choose the plan in the Class (Plan) dropdown field'
            }
          />
        </Grid>
      )}
    </Formik>
  );
}
