import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsListForFilters,
  getDistributorsResponse,
  getRMsList,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  DistributorTypesForUserManageMent,
  USER_ROLES,
} from '../../utils/constant';
import { InfoDialog, Notes } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { applicationComparison, applyRoleBasedStatus } from '../../utils/utilityFunctions';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import {
  DistributorListType,
  DistributorNewList,
  RMType,
  SupportRmProp,
} from '../../redux-store/types/api-types';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ConfirmationDialog } from '../commonComponents';
import MFSelectField from '../../lib/formik/SelectField';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { FundProps } from '../../redux-store/types/funds';
import { DistributorChannelMaster, branchMaster } from '../../utils/locationMasters';
import { nonDigitRegex } from '../../utils/regex';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  // filledBy: string | null;
  subdistributorPhone: string | null;
  subdistributorCode: string | null;
  subdistributorEuin: string | null;
  distributorEmail: string | null;
  distributorPhone: string | null;
  rmBranch: string | null;
  subdistributorBranch: string | null;
  distributorBranch: string | null;
  distributorChannel: string | null;
  rm_id: number | null | undefined;
  phone: string | null;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = ({ values }: { values: any }) => {
  return (
    <>
      <GridItem>
        <MFTextField name="firstName" label="RM Name" placeholder="Enter RM name" disabled={true} />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RM Email ID"
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <SearchableSelect
          items={branchMaster}
          name="rmBranch"
          label="AMC Branch *"
          placeholder="Enter AMC Branch"
        />
      </GridItem>
      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
  loader,
  rmsList,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
  loader: boolean;
  rmsList: RMType[];
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [srmsLoading, setSrmsLoading] = useState(false);
  const isDistributorLogin = [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role);
  const { application } = useSelector((store: RootStateType) => store.application);

  const [openDialog, setDialog] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };
  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) &&
            values.distributorId &&
            ![
              application?.distributor?.userId?.toString(),
              application?.subDistributor?.userId?.toString(),
            ].includes(application?.createdBy as string) && (
              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  sx={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 14,
                    py: 0.5,
                    px: 3,
                  }}
                  onClick={handleDialogOpen}>
                  Clear Distributor/ Distributor&apos;s RM details
                </Button>
              </Grid>
            )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Code *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: (item.name || 'NA') + ' - ' + item.arnCode,
                  value: item.id,
                }))}
              onChange={async ({ target: { value } }) => {
                try {
                  const distributorCode = distributors.find((item) => item.id === value);
                  const {
                    id = null,
                    name,
                    distributorEmpCode = '',
                    euin = '',
                  } = distributorCode || {};
                  if (id) {
                    setSrmsLoading(true);
                    const res = (await dispatch(
                      getDistributorById(id)
                    )) as unknown as DistributorNewList;
                    const { user } = res || {};
                    const { email, phone } = user || {};
                    setValues({
                      ...values,
                      distributorName: name,
                      distributorId: id,
                      distributorEmail: email,
                      distributorPhone: phone,
                      subdistributorId: null,
                      subdistributorName: null,
                      subdistributorEmail: null,
                      subdistributorPhone: null,
                      subdistributorCode: distributorEmpCode || '',
                      subdistributorEuin: euin || '',
                    });
                    setSelectedDistributor(
                      (res?.subdistributors as unknown as SubDistributor[]) || []
                    );
                    setSrmsLoading(false);
                  }
                  // else {
                  //   setValues({
                  //     ...values,
                  //     distributorName: name,
                  //     distributorId: id,
                  //     subdistributorId: null,
                  //     subdistributorName: null,
                  //     subdistributorEmail: null,
                  //     subdistributorPhone: null,
                  //     subdistributorCode: null,
                  //     subdistributorEuin: null,
                  //   });
                  //   setSelectedDistributor(
                  //     distributors.find((distributor) => distributor.id === value)
                  //       ?.subdistributors || []
                  //   );
                  // }
                } catch (e: unknown) {
                  setSrmsLoading(false);
                  console.error((e as Error).message);
                }
              }}
              disabled={
                [
                  USER_ROLES.DISTRIBUTOR,
                  USER_ROLES.INVESTOR,
                  USER_ROLES.POAAPPROVER,
                  USER_ROLES.SUBDISTRIBUTOR,
                ].includes(role) ||
                [
                  application?.distributor?.userId?.toString(),
                  application?.subDistributor?.userId?.toString(),
                ].includes(application?.createdBy as string)
              }
              applyRenderValue={true}
              searchFieldPlaceholder={'Search Distributor'}
            />
            {srmsLoading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem>
          {values.distributorPhone && values.distributorEmail && (
            <>
              <GridItem>
                <MFTextField
                  name="distributorEmail"
                  label="Distributor Email"
                  placeholder="Enter Distributor Email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="distributorPhone"
                  label="Distributor Phone Number"
                  placeholder="Enter Distributor Phone Number"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Name - Code *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName + ' - ' + (item.distributors_RM_Code || 'NA'),
                      value: item.id,
                    }))}
                  onChange={({ target: { value } }) => {
                    const distributorCode = selectedDistributor.find((item) => item.id === value);
                    const {
                      id = null,
                      user,
                      subDistributorCode = '',
                      euin = '',
                    } = distributorCode || {};
                    const { email, phone } = user || {};

                    setValues({
                      ...values,
                      subdistributorId: id,
                      subdistributorEmail: email,
                      subdistributorPhone: phone,
                      subdistributorCode: subDistributorCode,
                      subdistributorEuin: euin,
                    });
                  }}
                  disabled={
                    [
                      USER_ROLES.DISTRIBUTOR,
                      USER_ROLES.INVESTOR,
                      USER_ROLES.POAAPPROVER,
                      USER_ROLES.SUBDISTRIBUTOR,
                    ].includes(role) ||
                    [
                      application?.distributor?.userId?.toString(),
                      application?.subDistributor?.userId?.toString(),
                    ].includes(application?.createdBy as string)
                  }
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorPhone"
                  label="Distributor's RM Phone Number"
                  placeholder="Enter Distributor's RM Phone Number"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <MFTextField
              name="subdistributorCode"
              label="Sub Distributor Code"
              placeholder="Enter Sub Distributor Code"
              disabled={true}
            />
          </GridItem>
          <GridItem>
            <MFTextField
              name="subdistributorEuin"
              label="EUIN"
              placeholder="Enter EUIN"
              disabled={true}
            />
          </GridItem>
          {/* <GridItem>
            <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" />
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem> */}
          <GridItem>
            <SearchableSelect
              items={DistributorChannelMaster}
              name="distributorChannel"
              label="Distributor Channel"
              placeholder="Enter Distributor Channel"
            />
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributorBranch"
              label="Distributor Location *"
              placeholder="Enter Distributor Location"
            />
          </GridItem>
          <GridItem>
            <SearchableSelect
              items={branchMaster}
              name="rmBranch"
              label="AMC Branch *"
              placeholder="Enter AMC Branch"
            />
          </GridItem>
          {isDistributorLogin && (
            <>
              <GridItem>
                <SearchableSelect
                  items={rmsList.map((rm) => ({ key: rm.name, value: rm.id }))}
                  name="rm_id"
                  label="AMC RM *"
                  placeholder="Enter AMC RM"
                  onChange={({ target: { value } }) => {
                    const getRm = rmsList.find((rm) => rm.id === value);
                    const {
                      user: { email = '', phone = '' },
                    } = getRm || { user: { name: '', email: '', phone: '' } };
                    setValues({
                      ...values,
                      rm_id: value,
                      phone,
                      email,
                    });
                  }}
                  disabled={[
                    application?.rm?.userId?.toString(),
                    application?.supportRm?.userId?.toString(),
                  ].includes(application?.createdBy as string)}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="email"
                  label="AMC RM Email ID *"
                  placeholder="Enter AMC RM Email ID"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="phone"
                  label="AMC RM Mobile *"
                  placeholder="Enter AMC RM Mobile"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          {/* {!!selectedDistributor?.length && ( */}
          {/* {!!selectedDistributor?.length && (
            <GridItem>
              <SearchableSelect
                items={branchMaster}
                name="subdistributorBranch"
                label="Sub Distributor Branch"
                placeholder="Enter Sub Distributor Branch"
              />
            </GridItem>
          )} */}
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please add the Name of the Subdistributor RM.'}
          />
        </>
      ) : loader ? (
        <>
          <GridItem>
            <Skeleton />
          </GridItem>
          <GridItem>
            <Skeleton />
          </GridItem>
        </>
      ) : (
        <>
          <Grid
            xs={12}
            sm={12}
            sx={{
              fontSize: 18,
              fontWeight: 500,
              color: 'rgb(97, 209, 214)',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              mt: { lg: 9, xs: 9 },
              mb: 2.9,
              ml: { xs: 3 },
            }}>
            No distributor mapped under this RM
          </Grid>
        </>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to clear Distributor details ?`}
        open={openDialog}
        setOpen={handleDialogClose}
        onSave={() => {
          handleDialogClose();
          setValues({
            ...values,
            distributorId: null,
            subdistributorId: null,
            distributorName: '',
            subdistributorName: '',
            subdistributorEmail: '',
            subdistributorPhone: '',
            subdistributorCode: '',
            subdistributorEuin: '',
            distributorEmail: '',
            distributorBranch: '',
            distributorPhone: '',
            distributorChannel: '',
          });
          setSelectedDistributor([]);
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;
  const isDistributor = [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role);
  const {
    distributor,
    rm,
    subdistributorId = null,
    subDistributor,
    // filledBy = '',
    rm_id,
    distributor_id,
    rmBranch = '',
    subdistributorBranch = '',
    distributorBranch = '',
    distributorChannel = '',
    supportRm,
    support_rm_id,
  } = application || {};

  const {
    name,
    id,
    user: { email: distributorEmail, phone: distributorPhone },
    distributorEmpCode: distributorEMPCode,
    euin: distributorEuin,
  } = distributor || {
    arnCode: '',
    name: '',
    id: null,
    user: { email: '', phone: '' },
    distributorEmpCode: '',
    euin: '',
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '', phone: RMPhone = '' },
  } = (isSupportRM ? supportRm : rm) || { empCode: '', user: { email: '', phone: '' } };

  const {
    subDistributorCode: subdistributorCode,
    euin: subdistributorEuin,
    user: { firstName: subdistributorName, email: subdistributorEmail, phone: subdistributorPhone },
  } = subDistributor || {
    subDistributorCode: '',
    euin: '',
    user: { firstName: '', email: '', phone: '' },
  };

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: rm_id !== null || support_rm_id !== null ? 'rm' : 'distributor',
    empCode,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    // filledBy,
    subdistributorPhone,
    subdistributorCode: distributorEMPCode || subdistributorCode,
    subdistributorEuin: distributorEuin || subdistributorEuin,
    distributorEmail,
    distributorPhone,
    rmBranch,
    subdistributorBranch,
    distributorBranch,
    distributorChannel,
    rm_id,
    phone: RMPhone,
  };

  const [distributorsLoader, setDistributorsLoader] = useState(false);
  const [rmsList, setRmList] = useState<RMType[]>([]);

  const fetchRmList = async (): Promise<RMType[]> => {
    let updateRMList = [];
    const rms = (await dispatch(
      getRMsList({ roleType: 'amc_rm', isActive: true })
    )) as unknown as RMType[];
    updateRMList = application?.rm_id && !application.rm.isActive ? [...rms, application.rm] : rms;
    return updateRMList;
  };

  useEffect(() => {
    let isComponentActive = true;
    let updateRMList: RMType[] = [];
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          setDistributorsLoader(true);
          const res = (await dispatch(
            getDistributorsListForFilters({ isActive: true })
          )) as unknown as Distributor[];
          const distributors = res || [];
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor],
                  } as Distributor,
                ]
              : distributors;
          let updateSubdistributor: SubDistributor[] = [];
          if (id && distributor?.type === DistributorTypesForUserManageMent.Company) {
            const getSubdistributors = (await dispatch(
              getDistributorById(id)
            )) as unknown as DistributorNewList;
            updateSubdistributor =
              getSubdistributors.subdistributors as unknown as SubDistributor[];
          }
          if (isSupportRM) {
            updateRMList = await fetchRmList();
          }
          const updatedDistributorWithSubDistributor = updatedDistributor
            .map((_distributor) => {
              if (_distributor.type === DistributorTypesForUserManageMent.Individual) {
                return _distributor;
              } else if (
                _distributor.type === DistributorTypesForUserManageMent.Company &&
                _distributor.subdistributors &&
                _distributor.subdistributors.length > 0
              ) {
                return _distributor;
              }
              return;
            })
            .filter((ele) => ele);
          if (isComponentActive) {
            setSelectedDistributor(updateSubdistributor);
            setDistributors(updatedDistributorWithSubDistributor as unknown as Distributor[]);
            setRmList(updateRMList);
            setDistributorsLoader(false);
          }
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
          if (isDistributor) {
            updateRMList = await fetchRmList();
            setRmList(updateRMList);
          }
        }
      } catch (e: unknown) {
        setDistributorsLoader(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application, isSupportRM, isDistributor]);

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const {
        distributorId,
        subdistributorId,
        // filledBy,
        rmBranch,
        subdistributorBranch,
        distributorBranch,
        distributorChannel,
      } = values;
      const { id, applicant1ReferenceId, currentStep = null, status, hasPOA } = application || {};
      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        rmBranch,
        subdistributorBranch: subdistributorId ? subdistributorBranch : '',
        distributorBranch,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        distributorChannel,
        // filledBy: filledBy,
        currentStep: currentStep === null ? 1 : currentStep,
        rm_id: values.rm_id,
      });
      let restSchemePlan = {};
      const isDistributorExists =
        !distributorId &&
        application?.distributor_id &&
        (application?.schemeId || application?.planId);
      if (distributorId || isDistributorExists) {
        try {
          let getPlanId: any[] = [];
          if (Number(distributorId) !== Number(application?.distributor_id) && distributorId) {
            getPlanId = (
              (await dispatch(getDistributorById(distributorId))) as unknown as DistributorListType
            )?.planDetails.planIds;
          } else if (isDistributorExists) {
            (
              (await dispatch(
                getDirectApplicableFunds({ isActive: true })
              )) as unknown as FundProps[]
            )?.map((fund) => {
              getPlanId.push(
                ...fund.plans?.filter((fund) => fund.isApplicableForDirect)?.map((plan) => plan.id)
              );
              return fund;
            });
          }
          if (
            application?.planId &&
            getPlanId?.length > 0 &&
            !getPlanId.map((ele) => ele?.toString()).includes(application?.planId?.toString())
          ) {
            restSchemePlan = {
              schemeId: null,
              planId: null,
              commitmentAmount: 0,
              setupFeePercentage: 0,
              managementFeesRate: 0,
              taxRate: 0,
              drawDownNumber: 0,
              contributionChequeAmount: 0,
              setupFeeAmount: null,
              setupFeeGST: null,
              totalSetupFee: null,
              setupFeeTDS: null,
              netSetupFeeLessTDS: null,
              totalAmountPayable: null,
              feeType: '',
              category: '',
              additionalReturnWithoutCatchup: '',
              catchup: '',
              hurdleRateOfReturn: '',
              sipStartDate: null,
            };
          }
        } catch (e) {
          console.error((e as Error).message);
        }
      }
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              rmBranch,
              subdistributorBranch: subdistributorId ? subdistributorBranch : '',
              distributorBranch,
              distributor_id: distributorId,
              subdistributorId,
              distributorChannel,
              ...restSchemePlan,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              // filledBy,
              rm_id: values.rm_id,
            },
            applicationId: id,
          })
        );
        history.push('contributor-details', { id, applicant1ReferenceId });
      } else if (checkApplication) {
        history.push('contributor-details', { id, applicant1ReferenceId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  const rmsListSorting = [...rmsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(
        selectedDistributor,
        distributors,
        isSupportRM,
        rmsList
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies whether the application is from an RM/Distributor’s RM.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5}>
            {[USER_ROLES.RM, rm_id && distributor_id && USER_ROLES.INVESTOR].includes(role) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: 'Direct', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail values={values} />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
                loader={distributorsLoader}
                rmsList={rmsListSorting}
              />
            )}
            {isSupportRM && rmsList?.length > 0 && (
              <>
                <GridItem>
                  <SearchableSelect
                    name="rm_id"
                    label="AMC's RM"
                    items={rmsListSorting.sort().map((item) => ({
                      key: item.name,
                      value: item.id,
                    }))}
                    searchFieldPlaceholder={'Search RM'}
                  />
                </GridItem>
                {values.rm_id && (
                  <>
                    <GridItem>
                      <MFTextField
                        name="email"
                        label="AMC's RM Email ID"
                        placeholder="Enter AMC's RM Email ID"
                        value={rmsList.find((item) => item.id === values.rm_id)?.user?.email}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem>
                      <MFTextField
                        name="phone"
                        label="AMC's RM Mobile Number"
                        placeholder="Enter AMC's RM Mobile Number"
                        value={rmsList.find((item) => item.id === values.rm_id)?.user?.phone}
                        disabled={true}
                        regexForFilterValue={nonDigitRegex}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}
          </Grid>
          <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5 }}>
            <MFSubmitButton label="Next" />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
